import { useRemixForm, } from "remix-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction} from "@remix-run/node";
import {Link, useSearchParams} from "@remix-run/react";
import {getSession, requireAnonymousRequestContext} from "~/service/session.server";
import {useIsPending} from "~/helper/misc";
import {StatusButton} from "~/component/status-button";
import {GeneralErrorBoundary} from "~/component/error-boundary";
import { Label } from "~/component/ui/label";
import {Input} from "~/component/ui/input";
import {SplitLayout} from "~/component/layout/SplitLayout";
import {initiateLoginWithEmail } from "~/service/user.server";
import {validateForm} from "~/form/abstract";
import {authDtoSchema } from "~/dto/auth.pro.dto";
import {z} from "zod";
import {formBadRequest} from "~/helper/responses.helper";
import FormView from "~/component/form/FormView";
import {FormError} from "~/component/form/FormError";
import {redirect} from "@remix-run/server-runtime";
import {Checkbox} from "~/component/ui/checkbox";
import {FormControl, FormField, FormItem, FormLabel, FormSimpleField} from "~/component/ui/form";
import useRootData from "~/hook/useRootData";
import {buildMeta} from "~/hook/buildMeta";

const schema = authDtoSchema.and(z.object({
  redirectTo: z.string().optional(),
  remember: z.boolean().default(false),
}));

const resolver = zodResolver(schema);

type FormData = z.infer<typeof schema>;

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymousRequestContext(request)
  return json({})
}

export async function action({ request }: ActionFunctionArgs) {
  const requestContext = await requireAnonymousRequestContext(request)
  const session = await getSession(request);

  const result = await validateForm<FormData>(request, resolver);
  if (result.errorResponse) {
    return result.errorResponse;
  }

  const formData = result.data;

  try {
    await initiateLoginWithEmail(requestContext, formData);

    const searchParams = new URLSearchParams();
    searchParams.set("email", formData.email);
    searchParams.set("remember", formData.remember ? "true" : "false");
    if (formData.redirectTo) {
      searchParams.set("redirectTo", formData.redirectTo);
    }
    return redirect(`/auth-confirm?${searchParams}`);
  } catch (e) {
    return formBadRequest(e);
  }
  // checkHoneypot(formData)
  // const submission = await parseWithZod(formData, {
  //   schema: intent =>
  //     LoginFormSchema.transform(async (data, ctx) => {
  //       if (intent !== null) return { ...data, session: null }
  //
  //       const session = await login(data)
  //       if (!session) {
  //         ctx.addIssue({
  //           code: z.ZodIssueCode.custom,
  //           message: 'Invalid username or password',
  //         })
  //         return z.NEVER
  //       }
  //
  //       return { ...data, session }
  //     }),
  //   async: true,
  // })

  // if (submission.status !== 'success' || !submission.value.session) {
  //   return json(
  //     { result: submission.reply({ hideFields: ['password'] }) },
  //     { status: submission.status === 'error' ? 400 : 200 },
  //   )
  // }
  //
  // const { session, remember, redirectTo } = submission.value

  // return handleNewSession({
  //   request,
  //   session,
  //   remember: remember ?? false,
  //   redirectTo,
  // })
}

export default function LoginPage() {
  const { browserEnv } = useRootData();

  const isPending = useIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') ?? null

  // useFormFocusError(actionData, [["email", emailRef]]);

  const form= useRemixForm<FormData>({
    mode: "onSubmit",
    resolver,
    defaultValues: browserEnv.ENV === "local" ? {
      email: "pro-place-owner@jayneapp.com",
      redirectTo,
    } : {
      email: "",
      redirectTo
    },
  });

  return (
    <SplitLayout>
      <FormView
        form={form}
      >
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Connexion</h1>
            <p className="text-balance text-muted-foreground">
              Entrez votre email pour votre connecter à votre compte
            </p>
          </div>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>

              <FormSimpleField
                name={"email"}
                control={form.control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    name={"email"}
                    id="email"
                    type="email"
                    placeholder="m@example.com"
                    required
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            {/*<div className="grid gap-2">*/}
            {/*  <div className="flex items-center">*/}
            {/*    <Label htmlFor="password">Mot de passe</Label>*/}
            {/*    <Link*/}
            {/*      to="/forgot-password"*/}
            {/*      className="ml-auto inline-block text-sm underline"*/}
            {/*    >*/}
            {/*      Mot de passe oublié ?*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*  <Input id="password" type="password" required/>*/}
            {/*</div>*/}


            <div className="flex items-center space-x-2">
              <FormField
                control={form.control}
                name="remember"
                render={({ field: { value, onChange, onBlur } }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-2">
                    <FormControl>
                      <Checkbox
                        checked={value}
                        onCheckedChange={onChange}
                        onBlur={onBlur}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        Se souvenir de moi
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <FormError form={form}/>

            <StatusButton
              className="w-full"
              status={"pending"}
              // status={isPending ? 'pending' : form.status ?? 'idle'}
              type="submit"
              disabled={isPending}
            >
              Connexion
            </StatusButton>

            {/*<Button variant="outline" className="w-full">*/}
            {/*  Login with Google*/}
            {/*</Button>*/}
          </div>
          <div className="mt-4 text-center text-sm">
            Vous n'avez pas de compte ?{" "}
            <Link to="/contact-us" className="underline">
              Contactez-nous
            </Link>
          </div>

          {/*<HoneypotInputs />*/}

          {/*<Field*/}
          {/*  labelProps={{ children: 'Username' }}*/}
          {/*  inputProps={{*/}
          {/*    // ...getInputProps(fields.username, { type: 'text' }),*/}
          {/*    autoFocus: true,*/}
          {/*    className: 'lowercase',*/}
          {/*    autoComplete: 'username',*/}
          {/*  }}*/}
          {/*  // errors={fields.username.errors}*/}
          {/*/>*/}

          {/*<Field*/}
          {/*  labelProps={{ children: 'Password' }}*/}
          {/*  inputProps={{*/}
          {/*    // ...getInputProps(fields.password, {*/}
          {/*    //   type: 'password',*/}
          {/*    // }),*/}
          {/*    autoComplete: 'current-password',*/}
          {/*  }}*/}
          {/*  // errors={fields.password.errors}*/}
          {/*/>*/}

          <input
            type={"hidden"}
            {...form.register("redirectTo")}
          />

        </div>
      </FormView>
    </SplitLayout>
  )
}

export const meta: MetaFunction<typeof loader> = ({ data, error, matches}) => {
  return buildMeta({
    path: "/forgot-password",
    title: "Connexion",
    description: "",
    error,
    matches,
  });
};

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}
